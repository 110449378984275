<template>
  <div class="page-head-tile">
    <div class="container">
      <div class="breadcrumbs-block">
        <ul class="breadcrumbs">
          <li class="bc-item">
            <router-link :to="{name: 'index'}">Главная</router-link>
            <i class="fa fa-angle-right"></i>
          </li>
          <li class="bc-item">
            <router-link :to="{name: route_name}">{{ name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="page-title">
        <h1 class="title-16"><strong>{{ name }}</strong></h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    name: {
      type: String,
      required: true
    },
    route_name: {
      type: String,
      required: true
    }
  },
};
</script>

<style scoped>

</style>
