<template>
  <div class="main-content">
    <page-title name="Достопримечательности" route_name="attractions"/>
    <div class="section">
      <div class="row">
        <div :key="index" class="col-one-third" v-for="(attractions, index) in items.data">
          <article class="post post-tp-8">
            <figure v-if="attractions.img_gallery.length">
              <router-link :to="{name: 'openAttractions', params: {id: attractions.id}}">
                <img :alt="attractions.name" :src="attractions.img_gallery[0].get_img.media_server_url + attractions.img_gallery[0].get_img.url" class="adaptive" style="object-fit: cover; height: 242px; width: 345px;">
              </router-link>
            </figure>
            <figure v-else>
              <router-link :to="{name: 'attractions', params: {id: attractions.id}}">
                <img :alt="attractions.name" :src="$store.state.system_data.base_url + '/img/placeholder.png'" class="adaptive" style="object-fit: cover; height: 242px; width: 345px;">
              </router-link>
            </figure>
            <h3 class="title-5">
              <router-link :to="{name: 'attractions', params: {id: attractions.id}}">{{ attractions.name }}</router-link>
            </h3>
            <div class="meta-tp-2">
              <div class="date"><span>{{ attractions.updated_at | human_date }}</span></div>
            </div>
          </article>
        </div>
      </div>
      <div class="page-nav" style="text-align: center;" v-if="items.total > items.per_page">
        <a @click.prevent="switchPage(items.next_page_url)" class="btn-2" href="" v-if="items.next_page_url !== null">Загрузить еще</a>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../components/layouts/PageTitle.vue';

export default {
  name: 'Attractions',
  components: { PageTitle },
  async asyncData (context) {
    await context.store.dispatch('attractionsListPage');
  },
  beforeCreate () {
    this.items = this.$store.state.attractions;
  },
  methods: {
    /**
     * Пагинация
     * @param url - ссылка из пагинации laravel
     */
    async switchPage (url) {
      if (url !== null) {
        this.$store.state.system_data.loading = true;
        await this.$store.dispatch('attractionsListPage', url);
        this.items.data.push(...new Set(this.$store.state.attractions.data));
        this.items.next_page_url = this.$store.state.attractions.next_page_url;
        this.$store.state.system_data.loading = false;
      }
    },
  },
  metaInfo () {
    return this.$seo(
      'Достопримечательности'
    );
  }
};
</script>
