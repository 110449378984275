import { render, staticRenderFns } from "./PageTitle.vue?vue&type=template&id=a6dc0ffe&scoped=true&"
import script from "./PageTitle.vue?vue&type=script&lang=js&"
export * from "./PageTitle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6dc0ffe",
  null
  
)

component.options.__file = "PageTitle.vue"
export default component.exports